import { ALL } from '@elo-kit/constants/general.constants'

import { PAYMENT_FORMS, PAYMENT_SYSTEMS, PAYPAL_PROVIDERS } from './paymentSettingShared.constants'
import { CURRENCY_KEYS } from './currencies.constants'

const DEFAULT_AVAILABLE_CURRENCIES_LIST = [CURRENCY_KEYS.eur]

/** lemonway */
export const LEMONWAY_CARD_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
export const LEMONWAY_SOFORT_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
export const LEMONWAY_BANK_WIRE_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
/** lemonway */

/** stripe */
export const STRIPE_CARD_ALLOWED_CURRENCIES = [
  CURRENCY_KEYS.usd,
  CURRENCY_KEYS.aed,
  CURRENCY_KEYS.afn,
  CURRENCY_KEYS.all,
  CURRENCY_KEYS.amd,
  CURRENCY_KEYS.ang,
  CURRENCY_KEYS.aoa,
  CURRENCY_KEYS.ars,
  CURRENCY_KEYS.aud,
  CURRENCY_KEYS.awg,
  CURRENCY_KEYS.azn,
  CURRENCY_KEYS.bam,
  CURRENCY_KEYS.bdd,
  CURRENCY_KEYS.bdt,
  CURRENCY_KEYS.bgn,
  CURRENCY_KEYS.bif,
  CURRENCY_KEYS.bmd,
  CURRENCY_KEYS.bnd,
  CURRENCY_KEYS.bob,
  CURRENCY_KEYS.brl,
  CURRENCY_KEYS.bsd,
  CURRENCY_KEYS.bwb,
  CURRENCY_KEYS.bzd,
  CURRENCY_KEYS.cad,
  CURRENCY_KEYS.cdf,
  CURRENCY_KEYS.chf,
  CURRENCY_KEYS.clp,
  CURRENCY_KEYS.cny,
  CURRENCY_KEYS.cop,
  CURRENCY_KEYS.crc,
  CURRENCY_KEYS.cve,
  CURRENCY_KEYS.czk,
  CURRENCY_KEYS.djf,
  CURRENCY_KEYS.dkk,
  CURRENCY_KEYS.dop,
  CURRENCY_KEYS.dzd,
  CURRENCY_KEYS.egp,
  CURRENCY_KEYS.etb,
  CURRENCY_KEYS.eur,
  CURRENCY_KEYS.fjd,
  CURRENCY_KEYS.fkp,
  CURRENCY_KEYS.gbp,
  CURRENCY_KEYS.gel,
  CURRENCY_KEYS.gip,
  CURRENCY_KEYS.gmd,
  CURRENCY_KEYS.gnf,
  CURRENCY_KEYS.gtq,
  CURRENCY_KEYS.gyd,
  CURRENCY_KEYS.hkd,
  CURRENCY_KEYS.hnl,
  CURRENCY_KEYS.hrk,
  CURRENCY_KEYS.htg,
  CURRENCY_KEYS.huf,
  CURRENCY_KEYS.idr,
  CURRENCY_KEYS.ils,
  CURRENCY_KEYS.inr,
  CURRENCY_KEYS.isk,
  CURRENCY_KEYS.jmd,
  CURRENCY_KEYS.jpy,
  CURRENCY_KEYS.kes,
  CURRENCY_KEYS.kgs,
  CURRENCY_KEYS.khr,
  CURRENCY_KEYS.kmf,
  CURRENCY_KEYS.krw,
  CURRENCY_KEYS.kyd,
  CURRENCY_KEYS.kzt,
  CURRENCY_KEYS.lak,
  CURRENCY_KEYS.lbp,
  CURRENCY_KEYS.lkr,
  CURRENCY_KEYS.lrd,
  CURRENCY_KEYS.lsl,
  CURRENCY_KEYS.mad,
  CURRENCY_KEYS.mdl,
  CURRENCY_KEYS.mga,
  CURRENCY_KEYS.mkd,
  CURRENCY_KEYS.mmk,
  CURRENCY_KEYS.mnt,
  CURRENCY_KEYS.mop,
  CURRENCY_KEYS.mro,
  CURRENCY_KEYS.mur,
  CURRENCY_KEYS.mvr,
  CURRENCY_KEYS.mwk,
  CURRENCY_KEYS.mxn,
  CURRENCY_KEYS.myr,
  CURRENCY_KEYS.mzn,
  CURRENCY_KEYS.nad,
  CURRENCY_KEYS.ngn,
  CURRENCY_KEYS.nio,
  CURRENCY_KEYS.nok,
  CURRENCY_KEYS.npr,
  CURRENCY_KEYS.nzd,
  CURRENCY_KEYS.pab,
  CURRENCY_KEYS.pen,
  CURRENCY_KEYS.pgk,
  CURRENCY_KEYS.php,
  CURRENCY_KEYS.pkr,
  CURRENCY_KEYS.pln,
  CURRENCY_KEYS.pyg,
  CURRENCY_KEYS.qar,
  CURRENCY_KEYS.ron,
  CURRENCY_KEYS.rsd,
  CURRENCY_KEYS.rub,
  CURRENCY_KEYS.rwf,
  CURRENCY_KEYS.sar,
  CURRENCY_KEYS.sbd,
  CURRENCY_KEYS.scr,
  CURRENCY_KEYS.sek,
  CURRENCY_KEYS.sgd,
  CURRENCY_KEYS.shp,
  CURRENCY_KEYS.sll,
  CURRENCY_KEYS.sos,
  CURRENCY_KEYS.srd,
  CURRENCY_KEYS.std,
  CURRENCY_KEYS.szl,
  CURRENCY_KEYS.thb,
  CURRENCY_KEYS.tjs,
  CURRENCY_KEYS.top,
  CURRENCY_KEYS.try,
  CURRENCY_KEYS.ttd,
  CURRENCY_KEYS.twd,
  CURRENCY_KEYS.tzs,
  CURRENCY_KEYS.uah,
  CURRENCY_KEYS.ugx,
  CURRENCY_KEYS.uyu,
  CURRENCY_KEYS.uzs,
  CURRENCY_KEYS.vnd,
  CURRENCY_KEYS.vuv,
  CURRENCY_KEYS.wst,
  CURRENCY_KEYS.xaf,
  CURRENCY_KEYS.xcd,
  CURRENCY_KEYS.xof,
  CURRENCY_KEYS.xpf,
  CURRENCY_KEYS.yer,
  CURRENCY_KEYS.zar,
  CURRENCY_KEYS.zmw,
]
export const STRIPE_SEPA_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
export const STRIPE_P24_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
export const STRIPE_SOFORT_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
/** stripe */

/** mango_pay */
export const MANGOPAY_CARD_ALLOWED_CURRENCIES = [
  CURRENCY_KEYS.eur,
  CURRENCY_KEYS.gbr,
  CURRENCY_KEYS.usd,
  CURRENCY_KEYS.chf,
  CURRENCY_KEYS.pln,
  CURRENCY_KEYS.nok,
  CURRENCY_KEYS.sek,
  CURRENCY_KEYS.dkk,
  CURRENCY_KEYS.cad,
  CURRENCY_KEYS.aud,
]
export const MANGOPAY_SEPA_ALLOWED_CURRENCIES = [CURRENCY_KEYS.eur, CURRENCY_KEYS.gbr]
export const MANGOPAY_SOFORT_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
export const MANGOPAY_BANK_WIRE_ALLOWED_CURRENCIES = [ALL]
/** mango_pay */

/** paypal */
export const PAYPAL_ALLOWED_CURRENCIES = [
  CURRENCY_KEYS.aud,
  CURRENCY_KEYS.brl,
  CURRENCY_KEYS.cad,
  CURRENCY_KEYS.cny,
  CURRENCY_KEYS.czk,
  CURRENCY_KEYS.dkk,
  CURRENCY_KEYS.eur,
  CURRENCY_KEYS.hkd,
  CURRENCY_KEYS.inr,
  CURRENCY_KEYS.ils,
  CURRENCY_KEYS.jpy,
  CURRENCY_KEYS.myr,
  CURRENCY_KEYS.mxn,
  CURRENCY_KEYS.nok,
  CURRENCY_KEYS.nzd,
  CURRENCY_KEYS.php,
  CURRENCY_KEYS.pln,
  CURRENCY_KEYS.gbp,
  CURRENCY_KEYS.rub,
  CURRENCY_KEYS.sgd,
  CURRENCY_KEYS.sek,
  CURRENCY_KEYS.chf,
  CURRENCY_KEYS.twd,
  CURRENCY_KEYS.thb,
  CURRENCY_KEYS.usd,
]
/** paypal */

/** direct_bank */
export const DIRECT_BANK_ALLOWED_CURRENCIES = DEFAULT_AVAILABLE_CURRENCIES_LIST
/** direct_bank */

export const PAYMENT_METHODS_AVAILABLE_CURRENCIES = {
  [PAYMENT_SYSTEMS.lemonWay]: {
    [PAYMENT_FORMS.card]: LEMONWAY_CARD_ALLOWED_CURRENCIES,
    // [PAYMENT_FORMS.sofort]: LEMONWAY_SOFORT_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.bankWire]: LEMONWAY_BANK_WIRE_ALLOWED_CURRENCIES,
  },
  [PAYMENT_SYSTEMS.stripe]: {
    [PAYMENT_FORMS.card]: STRIPE_CARD_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.sepa]: STRIPE_SEPA_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.sofort]: STRIPE_SOFORT_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.p24]: STRIPE_P24_ALLOWED_CURRENCIES,
  },
  [PAYMENT_SYSTEMS.mangoPay]: {
    [PAYMENT_FORMS.card]: MANGOPAY_CARD_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.sepa]: MANGOPAY_SEPA_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.sofort]: MANGOPAY_SOFORT_ALLOWED_CURRENCIES,
    [PAYMENT_FORMS.bankWire]: MANGOPAY_BANK_WIRE_ALLOWED_CURRENCIES,
  },
  [PAYMENT_SYSTEMS.paypal]: {
    [PAYPAL_PROVIDERS.nvp]: PAYPAL_ALLOWED_CURRENCIES,
    [PAYPAL_PROVIDERS.rest]: PAYPAL_ALLOWED_CURRENCIES,
  },
  [PAYMENT_SYSTEMS.directBank]: {
    [PAYMENT_FORMS.bankWire]: DIRECT_BANK_ALLOWED_CURRENCIES,
  },
}
