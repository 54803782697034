import { getCurrencyLabels } from 'constants/currencies.constants'
import { PAYMENT_SYSTEMS } from 'constants/paymentSettingShared.constants'
import { PAYMENT_METHODS_AVAILABLE_CURRENCIES } from 'constants/paymentProviderCurrencies.constants'

import { ascendingSort } from 'utils/helpers.utils'
import { arrayUnique } from 'utils/array.utils'
import { Currency } from 'shared/api/currencies.api'

export {
  convertToPrice,
  getCurrencyKey,
  getCurrencySignPosition,
  getCurrencyUnit,
  formatCurrencySelectOptions,
} from 'utils/currenciesShared.utils'

export const getCurrencyStatisticFilterValues = (
  list: Currency[] = []
): Record<string, { value: string; label: string }> => {
  const labels = getCurrencyLabels()
  return ascendingSort(list, 'id').reduce(
    (result, { key }) => ({
      ...result,
      [key]: {
        value: key,
        label: labels[key],
      },
    }),
    {}
  )
}

export const mapToCurrencyIdsArray = (list: Partial<Currency>[] = []): number[] =>
  list.map(({ currencyId, id } = {}) => Number(currencyId) || id)

export const getCurrencyId = (currenciesList: Currency[], key: string): number =>
  (currenciesList.find((item) => item.key === key) || {}).id || 1

export const getAvailableCurrencies = (
  currenciesList: Currency[],
  paymentSystem: string,
  paymentForm: string,
  paymentProvider: string
) => {
  if (!paymentSystem || paymentSystem === PAYMENT_SYSTEMS.mangoPay) {
    return currenciesList
  }

  const paymentMethodCurrencies = PAYMENT_METHODS_AVAILABLE_CURRENCIES[paymentSystem]

  if (paymentForm || paymentProvider) {
    return currenciesList.filter(({ key }) => paymentMethodCurrencies[paymentForm || paymentProvider].includes(key))
  }

  const currencies = arrayUnique(
    Object.values(paymentMethodCurrencies).reduce((result, item) => [...result, ...item], [])
  )

  return currenciesList.filter(({ key }) => currencies.includes(key))
}
